<template>
    <div>
        <b-button-toolbar class="mb-3">
            <b-button-group class="ml-auto">
                <b-button to="../list">
                    <b-icon icon="list"/>
                    Список
                </b-button>
                <b-button :to="{name:'payments.list', query:{contractId:this.$route.params.id}}">
                    <b-icon icon="list"/>
                    Платежи
                </b-button>
            </b-button-group>
        </b-button-toolbar>
        <b-overlay :show="!item">
            <b-table
                :stacked="true"
                :items="rows"
                :fields="fields"
            >
                <template #cell(status)="row">
                    {{ row.item.status }}
                    <b-button
                        v-if="row.item.status === 'active'"
                        @click="deactivate"
                    ><b-icon icon="trash"/> Отменить</b-button>
                </template>
                <template #cell(user)="row">
                    <router-link :to="{name:'users.view', params:{id:row.item.user_id}}">
                        {{ row.item.user.lastname }}
                        {{ row.item.user.firstname }}
                        {{ row.item.user.patronymic }}
                    </router-link>
                </template>
            </b-table>
            <b-table
                :items="item.documents"
                :fields="documentsFields"
            >
                <template #cell(url)="row">
                    <a
                        target="_blank"
                        :href="row.item.url"
                    >{{ row.item.url }}</a>
                </template>
            </b-table>
            <div style="text-align: right">
                <a
                    target="_blank"
                    :href="`/admin_api/contracts/${$route.params.id}/documentsZip?token=${$auth.token()}`"
                >Все документы одним архивом</a>
            </div>
        </b-overlay>
    </div>
</template>

<script>
export default {
    data() {
        return {
            fields: [
                {
                    key: "id",
                    label: "Id",
                },
                {
                    key: "user",
                    label: "Пользователь",
                },
                {
                    key: "status",
                    label: "Статус",
                },
                {
                    key: "pan",
                    label: "PAN",
                },
                {
                    key: "name",
                    label: "Название",
                },
                {
                    key: "workflow_class",
                    label: "Workflow",
                },
                {
                    key: "canceled_at",
                    label: "Отменен",
                    formatter(value) {
                        return value ? window.formatDatetime(value) : "";
                    },
                },
                {
                    key: "created_at",
                    label: "Добавлен",
                    formatter(value) {
                        return window.formatDatetime(value);
                    },
                },
                {
                    key: "updated_at",
                    label: "Изменен",
                    formatter(value) {
                        return window.formatDatetime(value);
                    },
                },
            ],
            documentsFields: [
                {
                    key: "name",
                    label: "Название",
                },
                {
                    key: "url",
                    label: "Ссылка",
                },
            ],
            item: null,
        };
    },
    computed: {
        rows() {
            if (!this.item) {
                return [];
            }

            return [this.item];
        },
    },
    mounted() {
        this.refreshData();
    },
    methods: {
        refreshData() {
            const id = parseInt(this.$route.params.id);

            this.axios.get(`/admin_api/contracts/${id}`).then(async ({data}) => {
                this.item = data;
            });
        },
        deactivate() {
            const id = parseInt(this.$route.params.id);

            this.axios.patch(`/admin_api/contracts/${id}`).then(async() => {
                this.$alertify.success("Услуга деактивирована");
                this.refreshData();
            })
        },
    },
};
</script>
